const names = [
	"Herma Alan",  
	"Elisabeth Folkes",  
	"Terrell Scherf", 
	"Gertrudis Freiberg",  
	"Bernarda Hertzog", 
	"Kelli Stoehr",  
	"Loria Dyar",  
	"Lavonda Doughty",  
	"Coy Storm",  
	"Zaida Amerson",  
	"Bethany Reinert",  
	"Seth Callender",  
	"Ebonie Lemond", 
	"Sona Abdul",  
	"Donte Raine",  
	"Zona Atchinson",  
	"Chae Phegley",  
	"Coreen Mcglothlin", 
	"Alberta Shire",
	"Charlott Castonguay" 
];


const behaviours = [
	"Passt sich mit Leichtigkeit an verschiedene Rollen, Aufgaben und Verantwortungen an.",
	"Strengt sich auch bei ungeliebten Aufgaben und Anforderungen an.",
	"Ist offen für andere Ideen, Vorstellungen und Werte.",
	"Reagiert gelassen und konstruktiv auf sich ändernde Umstände.",
	"Beharrt nicht auf Routinen, wenn die Umstände Anpassungen erfordern.",
	"Kann Prioritäten flexibel an die Situation anpassen.",
	"Bringt sich auch in heterogenen Gruppen konstruktiv ein.",
	"Ist bereit, die Meinung bei neuen Informationen anzupassen.",
	"Setzt und erfüllt Ziele auch unter Druck erfolgreich.",
	"Arbeitet auch unter wechselhaften Bedingungen effizient.",
	"Ist flexibel und anpassungsfähig. ",
	"Kann in unterschiedlichen Gruppen effizient arbeiten.",
	"Arbeitet sich schnell in neue Aufgaben ein.",
	"Arbeitet aufgrund von intrinsischer Motivation.",
	"Zeigt Enthusiasmus und Leidenschaft beim Lernen, Forschen und Meistern neuer Kompetenzen.",
	"Zeigt Interesse an neuen Themen und Aufgabenstellungen.",
	"Ist bereit zu freiwilliger zusätzlicher Arbeit.",
	"Demonstriert Bereitschaft für lebenslanges Lernen.",
	"Ist in der Lage, selbstständig zu arbeiten.",
	"Ergreift die Initiative, um Veränderungsprozesse in Gang zu bringen. ",
	"Schätzt es, Probleme alleine und ohne Unterstützung oder Begleitung angehen zu können.",
	"Ist neugierig auf neues Wissen.",
	"Getraut sich, sinnvolle Risiken einzugehen",
	"Nimmt aktiv am Unterricht teil.",
	"Zeigt Resilienz, wenn sich sinnvoll eingegangene Risiken nicht auszahlen.",
	"Vermag sich über Neues zu wundern.",
	"Arbeitet aufgrund der Herausforderungen, die die Materie stellt.",
	"Fragt nach Klärung, wenn ein unklarer Arbeitsauftrag ansteht.",
	"Geht im eigenen Lernprozess über das hinaus, was verlangt wird.",
	"Geht komplexe Aufgaben selbstständig an. ",
	"Will sich selber verbessern und gibt sich nicht zu schnell zufrieden. ",
	"Zieht Alternativen in Betracht, statt sich mit der erstbesten Lösung zufrieden zu geben.",
	"Stellt Fragen, um Wissenslücken zu beheben.",
	"Bleibt hartnäckig an der Arbeit, bis diese erfolgreich beendet ist.",
	"Greift auf gemachte Erfahrungen zurück, wenn mit neuen Problemen konfrontiert.",
	"Identifiziert neue Probleme, die gelöst werden müssen und geht den Ursachen hartnäckig und gekonnt auf den Grund.",
	"Holt sich Hilfe, wenn eine Lösung nicht herausgefunden werden kann.",
	"Setzt verschiedene Strategien zur Problemlösung ein.",
	"Identifiziert wichtige Fragestellungen und getraut sich diese zu stellen.",
	"Vertritt die eigene Meinung auch in grösseren Gruppen.",
	"Analysiert und bewertet Argumente, Behauptungen, Meinungen und alternative Standpunkte effektiv.",
	"Ist in der Lage eigenständig Entscheidungen zu treffen.",
	"Teilt komplexe Probleme in einfachere Teilprobleme auf, ehe sie angegangen werden.",
	"Bringt aktiv Vorschläge zur Bearbeitung und Lösung von Aufgaben ein.",
	"Gibt sich nicht zu schnell zufrieden, sondern geht den Dingen hartnäckig nach.",
	"Nimmt Gegebenes nicht einfach hin, sondern unterzieht es einer kritischen Prüfung. ",
	"Nimmt Situationen aus einer originellen und oftmals interessanten Sichtweise wahr.",
	"Beweist in der Arbeit Originalität und Erfindergeist.",
	"Bringt eigene Ideen und Vorschläge ein.",
	"Stellt Verbindungen zwischen unterschiedlichen Informationen und Argumenten her. ",
	"Bringt neue und hochwertige Ideen ein.",
	"Setzt Informationen kreativ ein, um Probleme erfolgreich anzugehen.",
	"Ist offen für das Unbekannte, Kreative und Inspirierende. ",
	"Entwickelt nützliche, neuartige, originelle und ausgeklügelte Ideen, Vorschläge und Lösungen.",
	"Beweist ein ausgeprägtes kreatives Denken.",
	"Verknüpft Lernerfahrungen aus unterschiedlichen Bereichen.",
	"Geht in der Lösung von Problemen oder Bearbeitung von Aufgaben häufig eigene gedankliche Wege.",
	"Bereichert die Gruppenarbeit durch eigene Ideen und Vorschläge.",
	"Verknüpft erfolgreich neue mit vergangenen (Lern-)erfahrungen.",
	"Verfängt sich nicht in auswegslosen Situationen, sondern geht auch neue Wege.",
	"Beweist die Fähigkeit, einfache Projekte sowie kleinere Teams zu leiten.",
	"Engagiert sich bei gemeinsamen Aktivitäten.",
	"Ist in der Lage andere Sichtweisen einzunehmen und kann dadurch potentielle Missverständnisse verhindern.",
	"Legt ein professionelles Verhalten an den Tag. ",
	"Verteilt Arbeitsauträge in Kleingruppen gerecht, ohne andere zu benachteiligen.",
	"Ist bereit, für sein eigenes Handeln und seine Arbeitsergebnisse einzustehen.",
	"Übernimmt Verantwortung für Arbeiten, Lösungen oder Produkte.",
	"Hilft mit, dass das Team eine gute Gemeinschaft ist.",
	"Zeigt ein hohes organisations-bezogenes Engagement.",
	"Übernimmt Verantwortung für eigene Misserfolge und sucht, im Rahmen der Möglichkeiten, nach Bewältigungsstrategien.",
	"Handelt verantwortungsbewusst und hat das Interesse der Gemeinschaft präsent.",
	"Beweist Integrität und ethisches Verhalten beim Rückgriff auf Einflussnahme und Macht.",
	"Führt durch Respekt und Kompetenz, statt durch Autorität. ",
	"Motiviert und inspiriert andere.",
	"Demonstriert ausgeprägte Führungsqualitäten.",
	"Beweist die Fähigkeit, das eigene Lernen zu steuern.",
	"Beweist ein gutes Zeitmanagement.",
	"Beweist Fokus und Konzentration und lässt sich nicht ablenken.",
	"Erledigt Aufgaben auch ohne Aufsicht zuverlässig und gründlich.",
	"Fertigt die Hausaufgaben sorgfältig und regelmässig an.",
	"Führt übertragene und selbst gewählte Aufgaben zuverlässig durch.",
	"Hält Deadlines zuverlässig ein, ohne daran erinnert werden zu müssen.",
	"Hält sich an Abmachungen und Vereinbarungen.",
	"Hat das Material bei sich. ",
	"Ist in der Lage, Arbeitsanweisungen zu folgen.",
	"Ist zuverlässig und pünktlich.",
	"Kann Gelerntes anwenden.",
	"Managt selbst hohe Arbeitsbelastungen gekonnt.",
	"Nimmt neue Aufgabenstellungen zielstrebig in Angriff.",
	"Nutzt die Zeit effizient.",
	"Plant den Arbeitsprozess zeitlich sinnvoll.",
	"Setzt Prioritäten, konzentriert sich auf das Wesentliche.",
	"Setzt sich selber realistische aber herausfordernde Ziele. ",
	"Teilt sich die Zeit in Arbeiten und Leistungsnachweisen angemessen ein.",
	"Arbeitet auch über einen längeren Zeitraum konzentriert.",
	"Arbeitet präzise und achtet auch auf Details.",
	"Befasst sich mit den eigenen Fähigkeiten und zeigt den Willen, diese weiterentwickeln zu wollen.",
	"Benutzt eine Sprache, die andere nicht diskriminiert.",
	"Beweist Offenheit für neue und unterschiedliche Perspektiven. ",
	"Demonstriert einen positven Umgang mit Fehlern und sieht sie als Gelegenheiten zum Lernen.",
	"Geht respektvoll und achtsam mit anderen Mitmenschen und deren Besitz um. ",
	"Geht respektvoll und verantwortungsbewusst mit Arbeitsmaterialien um.",
	"Hilft Teammitgliedern in schwierigen Situationen.",
	"Ist offen für Rückmeldungen zum eigenen Verhalten.",
	"Ist sensibel für die Bedürfnisse und Gefühle anderer.",
	"Ist sich der Auswirkungen der eigenen Handlungen auf andere und die Umwelt bewusst. ",
	"Kann Feedback annehmen und erfolgreich umsetzen.",
	"Kann die Leistungen anderer anerkennen und wertschätzen.",
	"Kann seine eigene Meinung und die eigenen Gefühle erfassen und artikulieren.",
	"Nimmt Beratung und Unterstützung in Anspruch.",
	"Reagiert positiv auf konstruktiv Kritik.",
	"Reagiert positiv auf Rückschläge.",
	"Reflektiert den eigenen Lernprozess, um die Lernfortschritte zu vergrössern.",
	"Reflektiert eigene Erfahrungen kritisch um künftige Fehler verhindern und Fortschritte erzielen zu können.",
	"Respektiert das Verhalten, die Meinungen und das Aussehen anderer Menschen.",
	"Strebt aktiv nach Feedback.",
	"Verhält sich höflich und freundlich im Umgang mit Anderen.",
	"Verhält sich respektvoll und fair anderen gegenüber.",
	"Zeigt Achtung vor den sozialen und demokratischen Wertvorstellungen anderer.",
	"Zeigt eine hohe Frustrationstoleranz.",
	"Zeigt eine positive und ethische Arbeitseinstellung.",
	"Schätzt die eigenen Leistungen treffend ein.",
	"Kann mit technologischen Hilfsmitteln umgehen und allfällige Probleme effektiv angehen.",
	"Geht souverän mit technischen Pannen oder Problemen um.",
	"Respektiert fremdes geistiges Eigentum im Internet.",
	"Kann sichere von unsicheren Quellen abgrenzen.",
	"Evaluiert Informationen kritisch und kompetent.",
	"Beschafft sich, ordnet und gliedert Informationen sinnvoll.",
	"Setzt verschiedene mediale und technische Werkzeuge sinnvoll ein.",
	"Geht der Zuverlässigkeit von Quellen hartnäckig auf den Grund.",
	"Setzt Technik gekonnt als ein Forschungstool, Organisations-, Evaluations- und Kommunikationsinstrument ein.",
	"Ist begabt im Umgang mit Informations- und Kommunikationstechnologie.",
	"Zeigt einen sicheren, legalen und ethischen Umgang mit Technologie (Soziale Netzwerke / Chats / …).",
	"Demonstriert einen souveränen Umgang mit Technologie.",
	"Weist fremde Quellen in Arbeiten und Präsentationen konsequent aus.",
	"Zeigt im Umgang mit anderen eine ausgeprägte sprachliche Ausdrucksfähigkeit.",
	"Hört anderen aufmerksam zu, wenn sie sprechen.",
	"Spricht laut, deutlich und verständlich.",
	"Ist in der Lage, Ideen in der gesprochenen, wie auch in der geschriebenen Sprache verständlich zu übermitteln.",
	"Setzt die Sprache gekonnt ein, um Gedanken und Ideen zu artikulieren.",
	"Weiss, wann es angemessen ist zuzuhören und wann geredet werden sollte.",
	"Drückt sich in der Gruppe oder im Plenum angemessen aus.",
	"Kommuniziert neue Ideen gekonnt.",
	"Beweist, in der Lage zu sein zuzuhören.",
	"Kommuniziert effektiv in allen möglichen Umgebungen.",
	"Übt Kritik auf konstruktive und angemessene Art und Weise aus.",
	"Meldet sich in Diskussionen aktiv zu Wort.",
	"Nimmt Konflikte wahr und übernimmt Verantwortung für eine konstruktive Konfliktlösung. ",
	"Arbeitet gut und effizient in Teams.",
	"Respektiert kulturelle und geschlechtliche Unterschiede.",
	"Ist in der Lage effizient mit anderen zu arbeiten, selbst wenn es sich um diverse Gruppen mit Mitgliedern unterschiedlicher Meinungen handelt. ",
	"Beteiligt sich intelligent und konstruktiv an Diskussionen.",
	"Setzt Social Skills gekonnt ein, um sich anbahnende Konflikte zu verhindern.",
	"Ist fähig auch bei unterschiedlichen Interessen fair und transparent zu verhandeln.",
	"Ist bereit, die eigenen Interessen zurückzustellen, wenn es die Ziele des Teams erfordern.",
	"Hält sich an die Teamregeln.",
	"Löst Konflikte gewaltfrei und zeigt Kompromissbereitschaft.",
	"Wertschätzt die Beiträge von Gruppenmitgliedern.",
	"Zeigt Bereitschaft Kompromisse einzugehen, die notwendig sind, um gemeinsame Ziele zu erreichen.",
	"Arbeitet auch in heterogenen Gruppen effizient.",
	"Setzt das Feedback der Gruppe in der eigenen Arbeit um."
];

const Res = {
	names() {
		return names;
	},
	behaviours() {
		return behaviours;
	}
};

export default Res;
