var utils = require("trigram-utils");

class NGramSearch{
	constructor(dimension=3){
		this.dimension = dimension;
		this.documents = [];
	};

	initDocuments(){
		if (!this.list) {
			throw "no list defined";
		}
		for (var document of this.list) {
			this.documents.push({doc: document, dic: this.createDic(document)});
		}
	}

	createDic(doc){
		var dic = utils.asDictionary(doc);
		dic["  " + doc[0].toLowerCase()] = 1;
		return dic;
	}

	compare(doc1, doc2){
		return this.dist(this.createDic(doc1), this.createDic(doc2));
	}

	dist(dict1, dict2){
		var total = 0;
		var match = 0;
		for (var key of Object.keys(dict1)) {
			total += dict1[key];
			if (dict2[key]) {
				match += Math.min(dict1[key], dict2[key]);
			}
		}
		if (total === 0 || match === 0) {
			return 0;
		}else{
			return match/total;
		}
	}

	search(doc){
		var results = [];
		var dic = this.createDic(doc);
		for(var document of this.documents){
			results.push({doc: document.doc, score: this.dist(dic, document.dic)});
		}

		return results
			.filter(res => res.score > 0.2)
			.sort(function(a, b) {
  			return b.score - a.score;
			}).slice(0, 10);
	}
}

export default NGramSearch;
