import Res from "../res/resources";

const NGramSearch = require("./NGramSearch.js");


(function() {

	var searchFiled = document.getElementById("search-field");
	var searchInput = document.getElementById("search-input");
	var slider = document.getElementById("slider");
	var nameSet = false;

	var nameSearch = new NGramSearch();
	nameSearch.list = Res.names();
	nameSearch.initDocuments();

	var behvaiourSearch = new NGramSearch();
	behvaiourSearch.list = Res.behaviours();
	behvaiourSearch.initDocuments();

	var search_fn = function(query, callback){
		var result;
		if (nameSet) {
			result = behvaiourSearch.search(query);
		}else{
			result = nameSearch.search(query);
		}
		callback(result);

	};

	var chooseOption = function(option){
		if (!nameSet) {
			nameSet = true;
			var name = document.createElement("div");
			name.classList.add("name");
			name.innerHTML = option.doc;
			searchFiled.insertBefore(name, searchFiled.firstChild);
			searchInput.value = "";
		}else{
			var name = document.createElement("div");
			name.classList.add("behaviour");
			name.innerHTML = option.doc;
			searchFiled.insertBefore(name, searchFiled.firstChild.nextSibling);
			searchInput.value = "";
			searchInput.style.display = "none";
			slider.style.display = "block";
			document.querySelectorAll("#slider input")[0].focus();
		}

	};


	autocomplete("#search-input", { hint: false }, [
	 {
		 source: search_fn,
		 displayKey: "doc"
	 }
	]).on("autocomplete:selected", function(event, suggestion, dataset) {
	 console.log(suggestion, dataset);
	 chooseOption(suggestion);
	});
})();
